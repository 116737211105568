import {
  getApplicationType,
  branding,
  language,
  environment,
  country,
} from './helpers'

const PLATFORM_STATE = {
  appType: getApplicationType(),
  branding,
  language,
  environment,
  country,
  name: 'partner_centre_spa',
}

const DEFAULT_USER_STATE = {
  admin: 'partner',
  id: '',
  signinType: 'username',
}

class GtmService {
  constructor() {
    this.data = {}
  }

  /**
   * helpers
   */
  pushToDataLayer() {
    window.dataLayer.push(this.data)
  }

  setEvent(event) {
    this.data = {}
    this.data.event = event
    return this
  }

  setPlatformData() {
    this.data.platform = PLATFORM_STATE
    this.data.landingUrl = window.location.pathname
    return this
  }

  setPageData(pageData) {
    this.data.page = pageData
    return this
  }

  setUserData(user) {
    if (user) {
      this.data.user = {
        ...DEFAULT_USER_STATE,
        id: user.nameIdentifier,
      }
    }
    return this
  }

  setRestaurantData(restaurant) {
    if (restaurant) {
      const { id, name } = restaurant
      this.data.restaurant = {
        id,
        name,
      }
    }
    return this
  }

  setCustomData(data) {
    Object.assign(this.data, data)
    return this
  }

  /**
   * events
   */
  applicationOnload(user) {
    this.setEvent('app_launch')
      .setPlatformData()
      .setUserData(user)
      .setRestaurantData(user?.restaurant)
      .pushToDataLayer()
  }

  pageTransition(router) {
    router.beforeEach((to, from, next) => {
      if (to.name === null || (to.path === from.path && from.name !== null)) {
        next()
        return
      }
      const pageData = {
        path: router.options.base + to.path.slice(1),
        group: to.meta.group,
        name: to.meta.name,
      }
      this.setEvent('trackPageview').setPageData(pageData).pushToDataLayer()
      next()
    })
  }

  customEvent(event, data) {
    this.setEvent(event).setCustomData(data).pushToDataLayer()
  }
}

export default GtmService
