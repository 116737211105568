import { tenant, environment as env } from './constants'

const TENANT_CONFIG = {
  uk: {
    language: 'en',
    branding: 'JustEat',
  },
  ie: {
    language: 'en',
    branding: 'JustEat',
  },
  au: {
    language: 'en',
    branding: 'Menulog',
  },
  nz: {
    language: 'en',
    branding: 'Menulog',
  },
  es: {
    language: 'es',
    branding: 'JustEat',
  },
  it: {
    language: 'it',
    branding: 'JustEat',
  },
}

export const getApplicationType = () => {
  if (document.cookie.indexOf('je-is-mobile-partnercentre') === -1) {
    return 'Web'
  }
  const userAgent = navigator.userAgent || navigator.vendor
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return 'unknown'
}

export const { branding } = TENANT_CONFIG[tenant]

export const { language } = TENANT_CONFIG[tenant]

export const environment = env.includes('production')
  ? 'production'
  : 'qa-staging'

export const country = tenant.toUpperCase()
